<template>
	<section id="app-features" class="grey lighten-3">
		<v-container class="text-center">
			<h2
				class="font-weight-bold text-uppercase mb-3"
				:class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
			>
				{{ $t('features') }}
			</h2>

			<v-responsive class="mx-auto mb-8" width="56">
				<v-divider class="mb-1"></v-divider>
				<v-divider></v-divider>
			</v-responsive>

			<v-responsive
				class="mx-auto title font-weight-light mb-8"
				max-width="720"
			>
				{{ $t('featuresText') }}
			</v-responsive>

			<v-container>
				<v-row>
					<v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
						<v-row>
							<v-col cols="12" md="3">
								<v-avatar class="elevation-12" size="86">
									<v-icon size="45" color="secondary">{{
										feature.icon
									}}</v-icon>
								</v-avatar>
							</v-col>
							<v-col cols="12" md="9">
								<p class="feature-title font-weight-bold mb-0">
									{{ feature.title }}
								</p>
								<div>
									{{ feature.content }}
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'AppFeatures',

	data() {
		return {
			//
		};
	},
	computed: {
		features() {
			const features = [
				{
					title: this.$t('dewormings'),
					content: this.$t('dewormingsFeatureContent'),
					icon: 'mdi-shield-bug-outline',
				},
				{
					title: this.$t('vaccines'),
					content: this.$t('vaccinesFeatureContent'),
					icon: 'mdi-eyedropper-variant',
				},
				{
					title: this.$t('nutrition'),
					content: this.$t('nutritionFeatureContent'),
					icon: 'mdi-food-steak',
				},
				{
					title: this.$t('alergies'),
					content: this.$t('alergiesFeatureContent'),
					icon: 'mdi-alert-outline',
				},
				{
					title: this.$t('sicknesses'),
					content: this.$t('sicknessesFeatureContent'),
					icon: 'mdi-hospital-box-outline',
				},
				{
					title: this.$t('notifications'),
					content: this.$t('notificationsFeatureContent'),
					icon: 'mdi-comment-alert-outline',
				},
			];

			return features;
		},
	},
};
</script>

<style lang="scss" scoped>
.feature-title {
	font-size: 24px;
}
</style>
